import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, isRef as _isRef, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "max-440"
};
const _hoisted_2 = {
  class: "isCards"
};
const _hoisted_3 = {
  class: "buttons"
};
import { router } from "@/router";
import { ref, watch } from "vue";
import { recharge } from "@/http";
import { showToast } from "vant";
export default {
  __name: 'recharge',
  emits: ['back'],
  setup(__props, {
    emit: __emit
  }) {
    const back = () => {
      router.back();
    };
    let amount = ref(null);
    const confirmOrder = () => {
      recharge({
        amount: amount.value
      }).then(({
        msg,
        code
      }) => {
        showToast(msg);
        if (code === 200) {
          router.back();
        }
      });
    };
    const emit = __emit;
    const emitBacks = () => {
      emit('back', 0);
    };
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_cell_group = _resolveComponent("van-cell-group");
      const _component_van_button = _resolveComponent("van-button");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
        class: "back acea-row row-left",
        onClick: emitBacks
      }, [_createVNode(_component_van_icon, {
        name: "arrow-left",
        size: "20"
      }), _createElementVNode("span", null, _toDisplayString(_ctx.$t('deposit')), 1)]), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_cell_group, {
        inset: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_van_field, {
          type: "number",
          border: false,
          modelValue: _unref(amount),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(amount) ? amount.value = $event : amount = $event),
          label: _ctx.$t('recharge_amount')
        }, {
          button: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("span", null, "USD", -1)])),
          _: 1
        }, 8, ["modelValue", "label"])]),
        _: 1
      })]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_van_button, {
        color: "#56F9AA",
        style: {
          "color": "#333",
          "border-radius": "16px"
        },
        block: "",
        round: "",
        onClick: confirmOrder
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('confirm')), 1)]),
        _: 1
      })])])], 64);
    };
  }
};